<script setup>
const props = defineProps({
  events: {
    type: Array,
    default: () => [],
  },
  columnSize: {
    type: Number,
    default: 4,
  },
  showMoreButton: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['load-more'])

const showMore = () => {
  emit('load-more')
}
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-event-grid.css';
</style>

<template>
  <div class="nu-event-grid">
    <div :class="`row row-cols-md-${columnSize} row-cols-sm-2 row-cols-1 nu-event-grid-wrapper`">
      <div
        class="event-card"
        :key="index"
        v-for="(event, index) in events"
      >
        <NuEventCard :event="event" />
      </div>
    </div>
    <div
      class="d-flex justify-content-center"
      v-if="showMoreButton"
    >
      <a
        id="show-more-button"
        class="show-more"
        @click="showMore"
        >{{ $t('events.load_more') }}</a
      >
    </div>
  </div>
</template>
